<!-- <html lang="en">
<head>
    
    <title>Occupation Details</title>
</head>
<body>
    <div class="banner" >
        <img src="assets/assets/images/aptitude.jpg" alt="Occupation Banner Image">
    </div>
    <div class="occupation-details">
        <h1>{{ occupationData.title }}</h1>
        <p>{{ occupationData.description }}</p>
        <h2>Skills Needed:</h2>
        <ul>
          <li *ngFor="let skill of occupationData.skills">{{ skill }}</li>
        </ul>
    </div>
</body>
</html> -->

<header class="header" [style.background-image]="'url(' + occupationData.BannerImage + ')'">
    <div class="template-container2">
      <a href="#" class="logo"><img src="assets/images/logo.jpg" alt="logo"></a>
      <div class="textArea">
        <h2>Know your Occupations </h2>
        <p>Picture a career where you're not just another employee but a true expert. We're here to provide you with a comprehensive understanding of your desired occupation, unraveling its intricacies, prospects, and unique facets.</p>
      </div>
    </div>
  </header>
  <section class="spLngPath">
    <div class="template-container">
      <div class="spLngPathArea">
        <div class="bgClr">
          <h2>{{ occupationData.Title }}</h2>
          <p>{{ occupationData.Description }}</p>
          <!-- <div class="title">{{occupationData.OccupationDetails}}</div> -->
          <!-- <div class="bgClr"> -->
            <!-- <h2>{{ occupationData.Title }}</h2> -->
            <div [innerHTML]="sanitizeHtml(occupationData.OccupationDetails)"></div>
        <!-- </div> -->
          <!-- <div class="title">Skills Required:</div>
          <ul>
            <li *ngFor="let skill of occupationData.skills">{{ skill }}</li>
          </ul>
          <div class="title">Speech-Language Pathologists may hold titles</div>
          <ul>
            <li *ngFor="let skill of occupationData.skills">{{ skill }}</li>
          </ul>
       -->
        </div>
      </div>
    </div>
  </section>
  <footer class="footer">
    <div class="template-container">
      <div class="footerArea">
        <div class="para">
          <div class="footerLogo"><a href="#"><img src="assets/images/footerLogo.png" alt="footerLogo"></a></div>
          <p>VedAtma logo is a registered trademark of VedAtma Educational and Career Consulting Private Limited in India. Myers- Briggs Type Indicator and the MBTI logo are trademarks or registered trademarks of the Myers & Briggs Foundation, Inc. in the United States and other countries. Strong Interest Inventory and the Strong logo are trademarks or registered trademarks of The Myers-Briggs Company in the United States and other countries. The Murphy-Meisgeier Type Indicator for Children, MMTIC are registered trademarks of Elizabeth Murphy and Charles Meisgeier in the United States and other countries. The MMTIC logo are trademarks or registered trademarks of the Center for Applications of Psychological Type, Inc. in the United States and other countries.</p>
        </div>
        <div class="pic">
          <div class="title">CERTIFICATION</div>
          <ul>
            <li><a href="#"><img src="assets/images/certLogo1.jpg" alt="certLogo1"></a></li>
            <li><a href="#"><img src="assets/images/certLogo2.jpg" alt="certLogo2"></a></li>
            <li><a href="#"><img src="assets/images/certLogo3.jpg" alt="certLogo3"></a></li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <a href="" class="vstBtn">Visit Our Website</a>
        <p>Copyright @ 2023 VedAtma Educational and Career Consulting Pvt Ltd</p>
      </div>
    </div>
  </footer>



