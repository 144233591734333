import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, flatMap } from 'rxjs/operators';

import { AppService } from '../../app.service';

import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';


const URL = `${environment.apiUrl}`;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private storage: LocalStorageService,
    private appService: AppService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.storage.retrieve('user') && this.storage.retrieve('user')['accessToken']) {
      const authToken = this.storage.retrieve('user')['accessToken'];
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        // this.router.navigate(['/auth/login']);
        return this.handle401Error(request, next);
      }

      const error = err.error || '';
      return throwError(error);
    }));

  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
     return this.appService.geRrefreshToken().pipe(
      switchMap((token: any) => {
        const authToken = this.storage.retrieve('user')['accessToken'];
        if (authToken) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`
            }
          });
        }
        return next.handle(request)
          .pipe(catchError(err => {
            const error = err.error || '';
            return throwError(error);
          }));
      }));
  }

}
