import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';

@Injectable()

export class NonAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private storage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.storage.retrieve('user') && this.storage.retrieve('user')['accessToken']) {
      // console.log('Path name',window.location.pathname);
      if((window.location.pathname as string).includes('reset-mail')){
        return true;
      }else{
        this.router.navigate(['/']);
      }
      // return true;
    } else {
      return true;
    }
  }

}
