import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NonAuthGuard } from './shared/guards/non-auth.guard';
import { AuthGuard } from './shared/guards/auth.guard';

import { SharedModule } from './shared/shared.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AppService } from './app.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FeeSuccessComponent } from './fee-success/fee-success.component';
import { FeeFailureComponent } from './fee-failure/fee-failure.component';
import { PaymentsComponent } from './payments/payments.component';
import { OccupationDetailsComponent } from './occupation-details/occupation-details.component';
import { NewRegistrationFormatComponent } from './auth/new-registration-format/new-registration-format.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    PageNotFoundComponent,
    FeeSuccessComponent,
    FeeFailureComponent,
    PaymentsComponent,
    OccupationDetailsComponent,
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    // RecaptchaModule,
    NgxWebstorageModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    AppRoutingModule
  ],
  providers: [
    NonAuthGuard,
    AuthGuard,
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
