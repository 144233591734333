import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() pageNumber = 1;
  @Input() totalItems = 0;
  @Input() itemsPerPage = 0;

  @Output() pageNoEmitter = new EventEmitter();

  pages = 0;
  pageWindow = [];

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalItems) {
      this.setPages();
      this.setInitWindow();
    }
  }

  setPages() {
    this.pages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  setInitWindow() {
    this.pageWindow = [];
    //let currentPageCount = this.pageNumber;
    //let currentCount = currentPageCount % 3 == 0 ? ((currentPageCount / 3) | 0) : ((currentPageCount / 3) | 0) + 1;
    //let startCount = 1;
    //let endCount = 3;
    //if (currentCount == 0 && currentPageCount < 3) {
    //  currentCount = 0;
    //}
    //else if (currentCount == 0 && currentPageCount == 3) {
    //  currentCount = 0;
    //}
    //else if (currentCount > 1) {
    //  currentCount = currentCount * 3;

    //}
    //if (currentCount > 3) {
    //  startCount = (currentCount - 3) + 1;
    //  endCount = currentCount > this.pages ? this.pages : currentCount;
    //}
    //for (let i = startCount; i <= endCount; i++) {
    //  this.pageWindow.push(i);
    //}
    for (let i = 1; i <= this.pages; i++) {
      this.pageWindow.push(i);
    }
  }

  clickPrevious() {
    let pageNo;
    if (this.pageWindow.length == 0) {
      return;
    }
    if (this.pageNumber == 1) {
      return;
    }
    this.pageNumber = this.pageNumber - 1;
    this.pageNoEmitter.emit(this.pageNumber);

    if (this.pageWindow[0] != 1) {
      const first = this.pageWindow[0]
      const ind = this.pageWindow.length - 1
      this.pageWindow.splice(ind, 1);
      this.pageWindow.splice(0, 0, first - 1);
    }
  }

  clickNext() {
    let pageNo;
    if (this.pageWindow.length == 0) {
      return;
    }
    if (this.pageNumber == this.pages) {
      return;
    }
    this.pageNumber = this.pageNumber + 1;
    this.pageNoEmitter.emit(this.pageNumber);

    if (this.pageWindow[this.pageWindow.length - 1] != this.pages) {
      const last = this.pageWindow[this.pageWindow.length - 1];
      const ind = this.pageWindow.length - 1;
      this.pageWindow.splice(0, 1);
      this.pageWindow.splice(ind, 0, last + 1);
    }
  }

  clickPage(pageNo) {
    this.pageNoEmitter.emit(pageNo);
  }

}
