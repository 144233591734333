<div class="widgetBody">
  <div class="container">
    <div class="paymentSuccSection">
      <div class="paymentSuccess">
        <picture>
          <img src="assets/images/headerlogovedatma.png" alt="vedathma" routerLink="/">
        </picture>
        <h1>Thank you</h1>
        <p>Your payment has been successfully completed</p>
        <p hidden="hidden">Payment ID: {{ paymentId }}</p>
        <picture>
          <img src="assets/images/paymentsuccess.png" alt="payment">
        </picture>
        <div class="buttonGroup">
          <a style="cursor: pointer;" (click)="navigate('dashboard')" class="dashBoradBtn">GO TO DASHBOARD</a>
          <a style="cursor: pointer;" (click)="navigate('fee')" class="feeBtn">GO TO FEE</a>
        </div>
      </div>
      <div class="paymentDetailSection">
        <h2>Payment details</h2>
        <div class="paymentDetailWidget">
          <div class="paymentList">
            <div class="paymentListCol labelCol">Course Name<span class="eDoted">:</span></div>
            <div class="paymentListCol">
              <span class="value">{{ dctPaymentData?.CourseTitle}}</span>
            </div>
          </div>
          <div class="paymentList">
            <div class="paymentListCol labelCol">Payment tracking ID<span class="eDoted">:</span></div>
            <div class="paymentListCol"><span class="value">{{ dctPaymentData?.InvoiceNumber}}</span></div>
          </div>
          <div class="paymentList">
            <div class="paymentListCol labelCol">Fees<span class="eDoted">:</span></div>
            <!-- {{studentCourseDetails[0]?.CountryCode == 'IN' ?  '₹ ' : 'د.إ'}}&nbsp;{{ studentCourseDetails[0]?.SumAmount }} -->
            <div class="paymentListCol"><span class="value paymentFeeclr">{{dctPaymentData?.CountryCode == 'IN' ?  '₹ ' : 'د.إ'}}&nbsp;{{ dctPaymentData?.PayableAmount}}</span></div>
          </div>
          <div class="paymentList">
            <div class="paymentListCol labelCol">Fees title<span class="eDoted">:</span></div>
            <div class="paymentListCol">
              <span class="value" *ngIf="dctPaymentData?.Mode == 1">{{ dctPaymentData?.FeeTitle}}</span>
              <span class="value" *ngIf="dctPaymentData?.Mode == 2">{{ dctPaymentData?.CourseTitle}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
