import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

function _window(): any {
  // return the global native browser window object
  return window;
}
const URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  // private readonly apiUrl = 'https://api.razorpay.com/v1';
  // private readonly keyId = 'rzp_test_3hrDFKmoVBOiZp';
  // private readonly keySecret = 'jiEPbumcSfJicdNIm8DuaTah';

  constructor(private http: HttpClient) { }

  // public createOrder(amount: number, currency: string) {
  //   const url = `${this.apiUrl}/orders/`;
  //   const payload = {
  //     amount,
  //     currency,
  //   };
  //   const headers = new HttpHeaders()
  //     .set('Authorization', `Basic ${btoa(`${this.keyId}:${this.keySecret}`)}`);

  //   return this.http.post(url, payload, { headers });
  // }

  // public capturePayment(paymentId: string, amount: number) {
  //   const url = `${this.apiUrl}/payments/${paymentId}/capture`;
  //   const payload = {
  //     amount,
  //   };
  //   const headers = new HttpHeaders()
  //     .set('Authorization', `Basic ${btoa(`${this.keyId}:${this.keySecret}`)}`);

  //   return this.http.post(url, payload, { headers });
  // }

  public getResponse(razorpay_payment_id: any, razorpay_order_id: any, razorpay_signature: any, vedatmaOrderCode: any, phone: any) {
    return this.http.post(`${URL}/payment-response-razorpay?transaction_id=${razorpay_payment_id}&rp_order_id=${razorpay_order_id}&razorpay_signature=${razorpay_signature}&order_id=${vedatmaOrderCode}&phone=${phone}`, "")
    // return this.http.get(`${URL}`+'/FetchOccupationPersonality?studentID='+studentID+'&personalityID='+personalityID)
  }
  get nativeWindow(): any {
    return _window();
  }
}
