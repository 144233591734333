import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NonAuthGuard } from './shared/guards/non-auth.guard';
import { AuthGuard } from './shared/guards/auth.guard';

import { LayoutComponent } from './layout/layout.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SettingsModule } from './settings/settings.module';
import { ProfileModule } from './profile/profile.module';
import { AuthModule } from './auth/auth.module';
import { NotificationModule } from './notification/notification.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { PersonalityDashboardModule } from './personality-dashboard/personality-dashboard.module';
import {InterestSessionModule} from './interest-session/interest-session.module';
import { FeeManagementModule } from './fee-management/fee-management.module';
import { MotivatorModule } from './motivator/motivator.module';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FeeSuccessComponent } from './fee-success/fee-success.component';
import { FeeFailureComponent } from './fee-failure/fee-failure.component';
import { PaymentsComponent } from './payments/payments.component';
import { OccupationDetailsComponent } from './occupation-details/occupation-details.component';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      }, {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      }, {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [AuthGuard]
      }, {
        path: 'personality-dashboard/:id/:course/:instid',
        loadChildren: './personality-dashboard/personality-dashboard.module#PersonalityDashboardModule',
        canActivate: [AuthGuard]
      }, 
      {
        path: 'interest-session/:id/:course/:instid',
        loadChildren: './interest-session/interest-session.module#InterestSessionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'motivator/:id/:course/:instid',
        loadChildren: './motivator/motivator.module#MotivatorModule',
        canActivate: [AuthGuard]
      },
      
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
        canActivate: [AuthGuard]
      }, {
        path: 'contact-us',
        loadChildren: './contact-us/contact-us.module#ContactUsModule',
        canActivate: [AuthGuard]
      }, {
        path: 'notifications',
        loadChildren: './notification/notification.module#NotificationModule',
        canActivate: [AuthGuard]
      }, {
        path: 'fee',
        loadChildren: './fee-management/fee-management.module#FeeManagementModule',
        canActivate: [AuthGuard]
      },
     
    ]
  },
  {
   path: 'fee-payments',
   canActivate: [AuthGuard],
   component: PaymentsComponent
 },
 {
  path: 'occupation-details/:q',
  canActivate: [AuthGuard],
  component: OccupationDetailsComponent
},
  {
    path: 'fee-success/:id',
    canActivate: [AuthGuard],
    component: FeeSuccessComponent
  },
  {
    path: 'fee-failure/:id',
    canActivate: [AuthGuard],
    component: FeeFailureComponent
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [NonAuthGuard]
  },
  {
    path:'**',
    component:PageNotFoundComponent
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
