import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhone]'
})
export class PhoneDirective {

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) { }

  @HostListener('input', ['$event']) onEvent($event) {
    const initalValue = this.el.nativeElement.value;
    let value = initalValue.replace(/[^0-9+-]*/g, '');
    if (value.length > 14) {
      value = value.slice(0, 14);
    }
    this.control.control.setValue(value);
  }

}
