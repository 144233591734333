import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppService } from '../app.service';

@Component({
  selector: 'app-fee-success',
  templateUrl: './fee-success.component.html',
  styleUrls: ['./fee-success.component.scss']
})


export class FeeSuccessComponent implements OnInit {

  courseId;
  orderCode;
  dctPaymentData;
  paymentId: string | null = null;


  constructor(
    private router: Router,
    private storage: LocalStorageService,
    private appService: AppService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.courseId = this.storage.retrieve('courseId');
    this.route.params.subscribe(params => {
      try {
        this.orderCode = params['id'];
        this.paymentId = params['payment_id'] || null;
      }
      catch (err) {
        this.courseId = '';
        this.storage.clear('courseId');
      }
    })
    this.getPeymentData();
  }

  navigate(path) {
    if (path == 'fee') {
      this.router.navigate(['/fee/' + btoa(btoa(btoa(btoa(this.courseId))))]);
    }
    else {
      this.router.navigate(['/']);
    }
  }

  getPeymentData() {
    let data = {};
    data['OrderCode'] = this.orderCode;
    this.appService.getPaymentDetails(data).subscribe((res: any) => {
      this.dctPaymentData = res;
    })
  }

}
