import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.storage.retrieve('user') && this.storage.retrieve('user')['accessToken']) {
        return true;
    }
    this.storage.clear();
    this.router.navigate(['/auth/login']);
  }

}
