import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend, } from '@angular/common/http';
import { Router } from '@angular/router';

import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { occupationDetails } from './shared/directives/occupationDetailsStaticFile';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';

const URL = `${environment.apiUrl}`;

@Injectable()

export class AppService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private handler: HttpBackend,
    private storage: LocalStorageService
  ) {
    this.http = new HttpClient(handler);
  }


  geRrefreshToken() {

    const data = {
      customerId: this.storage.retrieve('user')['customerId'],
      mobileNumber: this.storage.retrieve('user')['mobileNumber'],
      refreshToken: this.storage.retrieve('user')['refreshToken']
    };

    return this.http.post<any>(`${URL}/get-access-token`, data)
      .pipe(tap((response: any) => {
        if (response['result'] && response['result'] == 'Valid') {
          const user = this.storage.retrieve('user');
          user['accessToken'] = response['accessToken'];
          user['refreshToken'] = response['refreshToken'];
          this.storage.store('user', user);
        } else {
          this.storage.clear();
          this.router.navigate(['/auth/login']);
        }
      },
        (err) => {
          this.storage.clear();
          this.router.navigate(['/auth/login']);
        }
      ));
  }

  getStudentBasicDetails(data: any) {
    return this.http.post(`${URL}/get-student-details`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
  getLinkedAccounts(data: any) {
    return this.http.post(`${URL}/get-linked-accounts`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPaymentDetails(data: any) {
    return this.http.post(`${URL}/Fetch-PaymentDetails`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = error.error;
    }
    return throwError(errorMessage);
  }



  getOccupationDetails(data: any) {
    // return occupationDetails;
    return this.http.post(`${URL}/FetchOccupationDetailsByUID`, data)
      .pipe(
        catchError(this.handleError)
      );
  }


  getCandidateCourse(data: any) {
    return this.http.post(`${URL}/FetchCandidateCourse`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
}
