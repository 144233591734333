import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-occupation-details',
  templateUrl: './occupation-details.component.html',
  styleUrls: ['./occupation-details.component.scss']
})
export class OccupationDetailsComponent implements OnInit {
  public occupationData: any;
  public candidateId: number;
  public candCourse: any;
  public occupationURLUID: string; // Define a variable to store the id
  valueFromTheQueryString: string;
  public courseId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appservice: AppService,
    private storage: LocalStorageService,
    private sanitizer: DomSanitizer,


  ) { }

  ngOnInit(): void {
    const isLoggedIn = this.storage.retrieve('user');
    if (isLoggedIn && isLoggedIn.userId) {
      this.candidateId = isLoggedIn.userId;

    }
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // Access the id from route parameters
      this.route.params.subscribe(params => {
        this.occupationURLUID = params['q']; // Assign the id from the route
      });
    }
    // this.showOccupationDetails();
    this.getCandidateCourse();
  }

  // getOccupationDetails() {
  //   const data = {
  //     id: this.id,
  //     candidateId: this.candidateId,
  //   };

  //   // Now you can use 'this.id' to pass the id to your API call
  //   this.occupationData = this.appservice.getOccupationDetails(data);
  //   console.log(this.occupationData);
  // }

  // getOccupationDetails() {
  //   ;
  //   // Check if this.id and this.candidateId are defined before making the request
  //   if (this.occupationURLUID !== undefined && this.candidateId !== undefined) {
  //     const data = {
  //       candidateID: this.candidateId,
  //       occupationURLUID: this.occupationURLUID

  //     };

  //     this.appservice.getOccupationDetails(data)
  //       .subscribe(response => {
  //         console.log(response);
  //         this.occupationData = response;
  //         console.log(this.occupationData);
  //       }, error => {
  //         // Handle error
  //         console.error(error);
  //       });
  //   } else {
  //     console.error('this.id or this.candidateId is undefined. Cannot make the request.');
  //   }
  // }

  getCandidateCourse(): void {
    const data = {
      candidateID: this.storage.retrieve('user').userId
    };

    this.appservice.getCandidateCourse(data)
      .subscribe((res: any) => {
        this.candCourse = res['CourseList']['CandidateCourseList'];


        // If there is data, show the Occupation Details page
        if (this.candCourse && this.candCourse.length > 0) {
          // const courseDetails = this.candCourse[0].CourseDetails;

          if (this.candCourse[0].CourseDetails && this.candCourse[0].CourseDetails.Status === 'Completed') {
            this.showOccupationDetails();
          } else {
            // If there is no data, navigate to the Page Not Found page
            this.router.navigate(['/page-not-found']);
          }
        }
        else {
          this.router.navigate(['/page-not-found']);
        }
      });
  }

  showOccupationDetails(): void {
    if (this.occupationURLUID !== undefined && this.candidateId !== undefined) {
      const data = {
        candidateID: this.candidateId,
        occupationURLUID: this.occupationURLUID
      };

      this.appservice.getOccupationDetails(data)
        .subscribe(response => {
          this.occupationData = response;
        }, error => {

        });
    }
    else {
    }
  }



  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}

