
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { PaymentService } from './payment.service';
import { environment } from 'src/environments/environment';
// import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';

// import { FeeManagementComponent } from '../fee-management/fee-management.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [PaymentService]
})
export class PaymentsComponent implements OnInit {

  // @ViewChild(FeeManagementComponent) FeeManagementComponent: FeeManagementComponent;

  paymentData: any
  paymentAmount: any
  razorpayorderid: any
  vedatmaordercode: any
  phone: any
  envValue = environment;
  userData = {};

  constructor(private winRef: PaymentService,
    private storage: LocalStorageService,
    // private location: Location,
    private router: Router,
    private ngZone: NgZone,
    private PaymentService: PaymentService,
  ) { }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === 'Escape') {
  //     this.router.navigateByUrl(localStorage.getItem('feeUrl'));
  //   }
  // }

  // @HostListener('window:popstate', ['$event'])
  // onPopState() {
  //   if (this.location.getState() !== null) {
  //     this.router.navigateByUrl(localStorage.getItem('feeUrl'));
  //   }
  // }

  ngOnInit() {
    this.vedatmaordercode = this.storage.retrieve('vedatmaordercode');
    this.razorpayorderid = this.storage.retrieve('razorpayorderid');
    this.paymentData = this.storage.retrieve('paymentData');
    this.userData = this.storage.retrieve('user');
    this.createRzpayOrder();
    this.paymentAmount = this.paymentData['amount'] * 100
    this.phone = this.userData['phoneNumber']
    // console.log("payment amount *100:",this.paymentAmount)
    // 
    // Add event listener for checkout closed event
    window.addEventListener('razorpay:checkout_closed', () => {
      this.router.navigateByUrl(localStorage.getItem('feeUrl'));
    });
  }

  // ngOnDestroy() {
  //   // Remove event listener on component destroy
  //   window.removeEventListener('razorpay:checkout_closed');
  // }

  createRzpayOrder() {
    // call api to create order_id
    // this.PaymentService.createOrder(this.paymentAmount,'INR').subscribe((res:any)=>{
    //   console.log('payment',res)
    // });
    this.payWithRazor(this.razorpayorderid);
  }

  payWithRazor(val: any) {
    const options: any = {
      key: this.envValue.razorpayApikey,
      amount: this.paymentAmount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: this.envValue.currency,
      name: this.userData['fName'], // company name or product name
      description: '',  // product description
      image: 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1491565200/hx0pgwt38u4r9vkwhggc.jpg', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response: any, error: any) => {

      options.response = response;
      // console.log("razorpay_payment_id:",response['razorpay_payment_id'] )
      let razorpay_payment_id = response['razorpay_payment_id'];
      let razorpay_order_id = response['razorpay_order_id'];
      let razorpay_signature = response['razorpay_signature'];

      this.PaymentService.getResponse(razorpay_payment_id, razorpay_order_id, razorpay_signature, this.vedatmaordercode, this.phone).subscribe((res: any) => {

        document.location.href = res;
      });
      //  //Response=> Success
      //  this.router.navigateByUrl('fee-success/:1111111');
      //  //Response=> Failure
      //  this.router.navigateByUrl('fee-failure/:1111111');
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      this.ngZone.run(() => this.router.navigateByUrl(localStorage.getItem('feeUrl')));

      // this.router.navigate([`fee`]);
      // this.FeeManagementComponent.ngOnInit();
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

}
