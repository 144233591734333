<nav aria-label="Page navigation example" *ngIf="pageWindow.length != 0">
  <ul class="pagination cust_pagination">
    <li class="page-item">
      <a class="page-link" href="javascript:void(0)" aria-label="Previous" [ngClass]="{'disabled': (pageNumber == 1)}" (click)="clickPrevious()">
        <span aria-hidden="true"><svg width="7.731" height="15.463" viewBox="0 0 7.731 15.463"><path d="M4153.731,1890l-7.731,7.732,7.731,7.731" transform="translate(-4146 -1890)" fill="#527383" /></svg></span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': window == pageNumber}" *ngFor="let window of pageWindow" (click)="clickPage(window)">
      <a class="page-link" href="javascript:void(0)" [ngClass]="{'active': window == pageNumber}">{{window}}</a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': (pageNumber == pages)}" (click)="clickNext()">
      <a class="page-link" href="javascript:void(0)" aria-label="Next">
        <span aria-hidden="true"><svg width="7.731" height="15.463" viewBox="0 0 7.731 15.463"><path d="M4146,1890l7.731,7.732-7.731,7.731" transform="translate(-4146 -1890)" fill="#527383" /></svg></span>
      </a>
    </li>
  </ul>
</nav>
