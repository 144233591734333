import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppService } from '../app.service';

@Component({
  selector: 'app-fee-failure',
  templateUrl: './fee-failure.component.html',
  styleUrls: ['./fee-failure.component.scss']
})
export class FeeFailureComponent implements OnInit {

  courseId;
  orderCode;
  dctPaymentData;

  constructor(
    private router: Router,
    private storage: LocalStorageService,
    private route: ActivatedRoute,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.courseId = this.storage.retrieve('courseId');
    this.route.params.subscribe(params => {
      try {
        this.orderCode = params['id'];
      } catch (err) {
        this.courseId = '';
        this.storage.clear('courseId');
      }
    })

    this.getPaymentData();
    history.replaceState(null, '', window.location.href);
    //history.replaceState({}, '', window.location.href);
  }

  navigate(path) {
    if (path == 'fee') {
      this.router.navigate(['/fee/' + btoa(btoa(btoa(btoa(this.courseId))))]);
    }
    else {
      this.router.navigate(['/']);
    }
  }

  getPaymentData() {
    let data = {};
    data['OrderCode'] = this.orderCode;
    this.appService.getPaymentDetails(data).subscribe((res: any) => {
      this.dctPaymentData = res;
    })
  }

}
