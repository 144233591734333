<section class="noDataHolder">
    <div class="noDataRow">
        <!-- <div class="titleCenter">
            <span class="textLight" >Sorry,</span>
            <h2>Page Not  Found</h2>
        </div> -->
        <div class="page404Row">
            <div class="page404Col">
                <img src="assets/images/nodata-img.jpg" alt="">
                <div class="pgTitle">
                    <h2>404</h2>
                    <h4>SORRY PAGE NOT FOUND</h4>
                </div>
                <a  routerLink="/" class="backBtn" >Back to home</a>
            </div>
        </div>
        <!-- <p>Please check the spelling or try searching for something else</p> -->
    </div>
</section>
