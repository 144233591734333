import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DateAgoPipe } from './dateAgoPipe/dateAgo.pipe';

import { PaginationComponent } from './pagination/pagination.component';

import { LoadingComponent } from '../shared/loading/loading.component';
// Directives
import { OnlyOneCharDirective } from './directives/only-one-char.directive';
import { OnlyCharDirective } from './directives/only-char.directive';
import { OnlyCharNumDirective } from './directives/only-char-num.directive';
import { PhoneDirective } from './directives/phone.directive';
import { OneNumCharDirective } from './directives/one-num.directive';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NumberToWordsPipe } from './NumToWords/number-to-words.pipe';

@NgModule({
  declarations: [
    OnlyOneCharDirective,
    OnlyCharDirective,
    OnlyCharNumDirective,
    OneNumCharDirective,
    PhoneDirective,
    DateAgoPipe,
    LoadingComponent,
    PaginationComponent,
    ConfirmationComponent,
    NumberToWordsPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DateAgoPipe,
    HttpClientModule,
    OnlyOneCharDirective,
    OnlyCharDirective,
    OnlyCharNumDirective,
    OneNumCharDirective,
    PhoneDirective,
    LoadingComponent,
    PaginationComponent,
    ConfirmationComponent,
    NumberToWordsPipe,
    NgSelectModule
  ]
})
export class SharedModule { }
