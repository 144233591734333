import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[onlyOneChar]'
})
export class OnlyOneCharDirective {

  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) { }

  @HostListener('input', ['$event']) onEvent($event) {
    const value = this.el.nativeElement.value || '';
    if (value) {
      if (value.length > 1) {
        this.control.control.setValue(value[1]);
      }
    }

  }

}
