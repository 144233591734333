
export const environment = {
  production: false,


  // Demo
  // ========================================================

  //apiUrl: 'http://vedatma.aabasoft.info/studentConnect/',
  //applicationlink: 'http://vedatma.aabasoft.info/',
  //applicationSubFolder: 'student',
  //uploadUrl: 'http://vedatma.aabasoft.info/studentConnect/Media/Reports/',
  //stripe_paymentapi: 'http://vedatma.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //paymentRedirectUrl: 'http://vedatma.aabasoft.info/studentConnect/payment-response-razorpay',
  //firebaseConfig: {
  //  apiKey: "AIzaSyCQp6t105Gl-pxDZLuYM_YatdWUiFUaZ4I",
  //  authDomain: "vedatmademo.firebaseapp.com",
  //  databaseURL: "https://vedatmademo-default-rtdb.firebaseio.com",
  //  projectId: "vedatmademo",
  //  storageBucket: "vedatmademo.appspot.com",
  //  messagingSenderId: "389225572613",
  //  appId: "1:389225572613:web:49ac1cda320e8bf48c2f6c"
  //},

  //paymentMode: 'TEST',
  //currency: 'INR',
  //pgName: 'Razor_Pay',

  //razorpayApikey: 'rzp_test_3hrDFKmoVBOiZp',
  //razorpaySecretkey: 'jiEPbumcSfJicdNIm8DuaTah',

  // feeCGST: 6,
  // feeSGST: 6 ,

  // emPayApiKey: '132fb9e4-334d-47fa-ba46-4a5cf480d705',
  // emPayApiSalt: 'f405ec6bd8e53073564de518333ec1aecdaeb0d5',


  // Live
  // =======================================================

  apiUrl: 'https://vedatmacounselling.com/counsellingconnect',
  applicationlink: 'https://vedatmacounselling.com/',
  applicationSubFolder: '',
  uploadUrl: 'https://vedatmacounselling.com/practitionerconnect/Media/Reports/',
  paymentRedirectUrl: 'https://vedatmacounselling.com/counsellingconnect/payment-response',
  stripe_paymentapi: 'https://stripeapi.vedatmacounselling.com/create-checkout-session',
  firebaseConfig: {
    apiKey: "AIzaSyCfNItDKTf1g7mscz3Wu1YXANLbeiJWnPM",
    authDomain: "vedatma-production.firebaseapp.com",
    databaseURL: "https://vedatma-production-default-rtdb.firebaseio.com",
    projectId: "vedatma-production",
    storageBucket: "vedatma-production.appspot.com",
    messagingSenderId: "236929135030",
    appId: "1:236929135030:web:f1f7c2e483e0288b0cd117"
  },

  paymentMode: 'LIVE',
  currency: 'INR',
  pgName: 'Razor_Pay',

  razorpayApikey: 'rzp_live_SDGD11xwlkfyeI',
  razorpaySecretkey: 'UVh4mudA2734TRwGLRuArGBN',

  recaptchaSiteKey: '6LfeWVYqAAAAAI7EeuOweDrhO4HSUx-bU2oabo_k', //with Chinnu s' support, taken new site key,since there was issue after migrating to new mockup server


  // Preprod
  // ========================================================

  //apiUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/',
  //applicationlink: 'http://vedatmapreprod.aabasoft.info/',
  //applicationSubFolder: 'student',
  //uploadUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/Media/Reports/',
  //stripe_paymentapi: 'http://vedatmapreprod.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //paymentRedirectUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/payment-response-razorpay',
  //firebaseConfig: {
  //  apiKey: "AIzaSyA3qdDCSQB5q46jLGgnreWxO7ApCc-Ywsk",
  //  authDomain: "vedatmapreprod.firebaseapp.com",
  //  projectId: "vedatmapreprod",
  //  storageBucket: "vedatmapreprod.firebasestorage.app",
  //  messagingSenderId: "598682543658",
  //  appId: "1:598682543658:web:22f6f06f6c78cd4d284553",
  //  measurementId: "G-1FPBFN7NPK"
  //},


  //preprod with demo firebase
  //============================================================================
  //apiUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/',
  //applicationlink: 'http://vedatmapreprod.aabasoft.info/',
  //applicationSubFolder: 'student',
  //uploadUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/Media/Reports/',
  //stripe_paymentapi: 'http://vedatmapreprod.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //paymentRedirectUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/payment-response-razorpay',
  //firebaseConfig: {
  //  apiKey: "AIzaSyCQp6t105Gl-pxDZLuYM_YatdWUiFUaZ4I",
  //  authDomain: "vedatmademo.firebaseapp.com",
  //  databaseURL: "https://vedatmademo-default-rtdb.firebaseio.com",
  //  projectId: "vedatmademo",
  //  storageBucket: "vedatmademo.appspot.com",
  //  messagingSenderId: "389225572613",
  //  appId: "1:389225572613:web:49ac1cda320e8bf48c2f6c"
  //},


  // localhost
  // ========================================================

  //apiUrl: 'http://localhost:62412/',
  //applicationlink: 'http://vedatmapreprod.aabasoft.info/',
  //applicationSubFolder: 'student',
  //uploadUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/Media/Reports/',
  //stripe_paymentapi: 'http://vedatmapreprod.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //paymentRedirectUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/payment-response-razorpay',
  //firebaseConfig = {
  //  apiKey: "AIzaSyA3qdDCSQB5q46jLGgnreWxO7ApCc-Ywsk",
  //  authDomain: "vedatmapreprod.firebaseapp.com",
  //  projectId: "vedatmapreprod",
  //  storageBucket: "vedatmapreprod.firebasestorage.app",
  //  messagingSenderId: "598682543658",
  //  appId: "1:598682543658:web:22f6f06f6c78cd4d284553",
  //  measurementId: "G-1FPBFN7NPK"
  //},
};

