import { Component, OnInit, HostListener } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';

import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selectedTab: string;
  isOpenProfile: boolean = false;
  userDetails: any = '';
  path = environment.apiUrl;
  isAnyAccountSelected:boolean=false;
  linkedAccountCount:number=0;
  userListHistory:any;
  StudentOrProfessional: any;
  constructor(
    private storage: LocalStorageService,
    private toastr: ToastrService,
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedTab = event.urlAfterRedirects;
      }
    });
    this.storage.observe('user')
      .subscribe((value) => {
        this.userDetails = this.storage.retrieve('user');
      });
      this.getStudentDetails();
      this.LinkedAccountCheck();
      this.AddAccountCheck();
  }
  LinkedAccountCheck(){
    let switchAccount=this.storage.retrieve('switchAccount');
    this.isAnyAccountSelected=!switchAccount;
  }
  AddAccountCheck(){
    let addAccountVisible=this.storage.retrieve('addAccount');
    this.addAccountVisible=addAccountVisible;
  }

  GetLinkedAccountList(){
    const data = {
      StudentID: this.storage.retrieve('user').userId
    }
    this.appService.getLinkedAccounts(data)
      .subscribe((res: any) => {
        const linkedAccountData:any=[];
        res.forEach(element => {
          let item={
            StudentID:element.StudentID,
            StudentCode:element.StudentCode,
            FirstName:element.FirstName,
            MiddleName:element.MiddleName,
            LastName:element.LastName,
            PrimaryEmail:element.PrimaryEmail,
            PhoneNumber:element.PhoneNumber,
            Status:element.Status,
            isSelected:element.StudentID ==this.storage.retrieve('user').userId?true:false
          }
          linkedAccountData.push(item);
        });
        this.storage.store('linkedAccounts', linkedAccountData);
      })
  }


  SwitchAccount(){
    this.isAnyAccountSelected=false;
    this.storage.store('switchAccount',true);
    if(this.router.url === '/'){
      window.location.reload(); 
    }else{
      this.router.navigate(['/']);
    }
  }
  OnAccountSelect(studentId){
    let user=this.storage.retrieve('user');
    let dataFromList=this.userListHistory;
    let data=dataFromList.find(x=>x.StudentID===studentId)
    user.fName=data.FirstName;
    user.mName=data.MiddleName;
    user.lName=data.LastName;
    user.phoneNumber=data.PhoneNumber;
    user.email=data.PrimaryEmail;
    user.userId=studentId;
    user.studentCode=data.StudentCode;
    this.storage.store('user',user);
    window.location.reload();
  }

  addAccountVisible:boolean=false;
  AddAccountNav(){
    this.addAccountVisible=true;
    this.storage.store('addAccount',true);
    if(this.router.url === '/'){
      window.location.reload(); 
    }else{
      this.router.navigate(['/']);
    }
  }


  onClickProfile() {
    this.isOpenProfile = !this.isOpenProfile;
  }

  errorHandler(event) {
    event.target.src = "assets/images/user.png";
  }

  confirmLogout: boolean = false;
  onClickLogout() {
    this.confirmLogout = true;
  }

  onConfirmLogout(confirm) {
    this.confirmLogout = false;
    if (confirm) {
      this.storage.clear();
      this.router.navigate(['/auth/login']);
    }
  }

  Dash()
  {
    this.router.navigate(['/']);

  }
  getStudentDetails() {
    if (this.storage.retrieve('user') && this.storage.retrieve('user').userId) {
      const data = {
        StudentID: this.storage.retrieve('user').userId
      }

      // this.profileService.getStudentDetailsByID(this.studentId)
      this.appService.getStudentBasicDetails(data)
        .subscribe((res: any) => {
          console.log("resres",res);
          
            const userVal = this.storage.retrieve('user');
            userVal['profileImg'] = res.ProfileImagePath;
            this.StudentOrProfessional = res.StudentOrProfessional
            console.log("this.StudentOrProfessional", this.StudentOrProfessional);
            
            this.linkedAccountCount=res.Linkedaccountcount;
            if(this.linkedAccountCount >1){
              this.GetLinkedAccountList();
            }
            this.storage.store('user', userVal);
            this.userDetails = this.storage.retrieve('user');
        })
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    this.isOpenProfile = false;
  }

}
