import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOneMum]'
})
export class OneNumCharDirective {

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) { }

  @HostListener('input', ['$event']) onEvent($event) {
    const initalValue = this.el.nativeElement.value;
    let value = initalValue.replace(/[a-zA-Z\!@$#%^&*\(\)\{\}\[\]\\|;:\'\"<>,.?/\`~\-_\+=]*/g, '');
    if (value.length > 1) {
        value = value[1];
    }

    this.control.control.setValue(value);
  }

}
